<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Answer Short Question
                    <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'short_question_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Answer Short Question</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will hear a question and give a simple and short answer. Often just one or a few words is enough.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening and speaking</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 5 - 6 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The recording will begin automatically. Sometimes, this item also has an image. Once the audio is finished, the microphone will start recording your voice and you will get no beep sound.</li>
                <li>Don't speak before the microphone starts because your voice won’t be recorded. Speak clearly, and don’t make any hurry. You’ll be able to record your answer only once.</li>
                <li>Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed”.</li>
                <li>Marks are awarded for a short, accurate answer. There are no extra marks for additional words.</li>
            </ul>
            </template>
            <!-- <iframe width="420" height="315"
                src="https://m.youtube.com/watch?v=jeV3TMjrLUM&t=10s">
                </iframe> -->
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" v-if="selectedquestion">
            <b-overlay class="fixedHeight" :show="show" rounded="sm" >
             <h4>Answer Short Question
                 <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
             </h4>
            <p class="questHeading">You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.</p>
            
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.short_question_id" :idName="'short_question_id'" :tableName="'tblShortQuestion'"/>
            </div>
            </div>
            <div v-if="questionrecording" class="audio-container"><audio id="questionRec" :src="questionrecording" controls /></div>
            <p class="recordMessage">{{recordMessage}}</p>
            <div class="record-settings">
              <audio-recorder
                    :mic-failed="micCheck"
                    :before-recording="beforeRecording"
                    :after-recording="afterRecording"
                    :pause-recording="pauseRecording"
                    :src=null
                    :time=".166" 
                    :class="{'disabledRecording':!recordingAllow}"
                    />
            </div>
            <div class="recorded-audio">
              <div v-for="(record, index) in recordings" :key="index" class="row recorded-item">
                <div class="audio-container col-12 left"><audio :src="record.src" controls /></div>
                <div class="col-12"><p style="padding-left:0px!important">AI Scoring is available after submission.</p></div>
              </div>
            </div>
            </b-overlay>
            <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 mb-2">
                    <b-button class="mr-2 pl-4 pr-4" :disabled="!this.recordStop || checkAutoNext" variant="outline-info" @click="showAnswerMarking()">Submit</b-button>
                    <!-- <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="showAnswerNew()">Submit</b-button>  -->
                    <b-button :disabled="checkAutoNext" variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button :disabled="apiResults ==null || checkAutoNext" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                  <span> | 
                  <toggle-button :width="100" :height="28" v-model="checkAutoNext" :value="false" :color="{checked: '#368f5b', unchecked: '#cae8d6', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Auto-Next', unchecked: 'Manual-Next'}"/>
                </span>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5 ">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12" v-if=" userAnswerResponse!='' & userAnswerResponse!=undefined ">
                    <h4 class="mb-3">Transcript
                    </h4>
                    <p>{{userAnswerResponse}}</p>
                </div> -->
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">{{question}}?</p>
                    <p style="display:block">{{possibleAnswer}}</p>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="result-Modal" ref="result-Modal" size="lg" centered hide-footer title="AI Result">
         <b-container class="resultModel" >
               <app-ResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button v-if="!checkAutoNext" class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
         <b-button v-if="checkAutoNext" class="mt-3" variant="outline-dark" left >Disappear in {{ closeCounter }} seconds</b-button>
       </b-modal>
       <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'short_question_id'"/></b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserShortAnswer'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
// import blobToBase64 from 'blob-to-base64'
import $ from "jquery";
//import LocalStorageService from '../../store/LocalStorageService';
const sdk = require("microsoft-cognitiveservices-speech-sdk");
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import ResultPresent from '../reuseable/resultPresent'
import DiscussionTab from '../reuseable/discussionTab'
import {bus} from '../../main'
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-ResultPresent':ResultPresent,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            show:false,
            selectedquestion: null,
            recordings: [],
            questionrecording: null,
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false,
            title:'Prepare',
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                short_question_id:0,
                user_recording:null,
                transcript:'',
                mock_test_id:0
            },
            recordStop:false,
            actualAnswer:'',
            marksGot:0,
            checkAnswerFlag:false,
            checkAutoNext:false,
            user_type:'Free',
            recordingAllow:false,
            currentQuestion:0,
            recordMessage:'Click to Start',
            recordingBlob:null,
            resultStatus:'AI-Result',
            apiResults:null,
            question:'',
            possibleAnswer:'',
            searchKey:'',
            searchQuestionList:null,
            transcript:'',
            microsoftTranscript:'',
            recognizer:null,
            currentUser:null,
            closeCounter:5,
            closeTime:null
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'speakmodule/fetchQuestion',
            saveAnswer: 'speakmodule/saveAnswer',
            getTranscripty:'speakmodule/getTranscripty',
            generateFileUpload:'speakmodule/generateFileUpload',
            fetchUserUsage:'auth/saveDashBoardData',
            
        }),
        ...mapGetters({
            getQuestionList: 'speakmodule/questions',
            answerResponse: 'speakmodule/AnswerResponse',
            GetUserUsageResponse:'auth/getDashBoardResponse'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].short_question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.short_question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.question=''
            this.possibleAnswer=''
            this.checkAnswerFlag=false
            this.recordingAllow = false
            this.recordingBlob=null,
            this.resultStatus='AI-Result',
            this.show=false
            ////console.log(questionId)
            this.actualAnswer = ''
            this.recordMessage='Click to Start'
            this.userAnswer.short_question_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.short_question_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
             var answer=this.selectedquestion.transcript.split('?')
            this.question = answer[0]
            this.possibleAnswer=answer[1]
                 //////console.log(this.selectedquestion);
            //this.questionservice('configshortquestion/blobId='+questionId).then(()=>{
                this.questionrecording = this.selectedquestion.blb //this.getQuestionList().data.blb;
                this.userAnswerResponse =''
                this.recordStop= false
                this.recordings = []
                this.startFlag = false
                this.$nextTick(function(){
                    this.$refs.discussionTab.updateQuestionId(questionId)
                    this.$refs.timer.setTime(3)
                    this.title = 'Prepare'
                    $('.ar-recorder__stop').click();
                    this.recordings = [];
                });
            //}); 
        },
        userUsage(){
        var data={
            user_id: this.currentUser.user_id,
            url:'users/userUsageCount'
          }
          this.fetchUserUsage(data).then(()=>{
            ////console.log(this.GetUserUsageResponse())
           this.currentUser.speaking_Counter= this.GetUserUsageResponse().data.speakingCount
            //this.$ls.remove('PackageType')
          })
        },
        checkUserAccess(){
            const user =this.currentUser
            if(user.user_type == 'Free' & user.speaking_Counter>=5)
            {
                return false
            }
            else if(user.user_type == 'standard' & user.speaking_Counter>=10)
            {
                return false
            }
            else{
                this.currentUser.speaking_Counter++
                return true
            }
        },
        // generateTranscriptFromRestult(apiResults){
        //     if(apiResults.correct_Words!=null)
        //     {
        //     for (let index = 0; index < apiResults.correct_Words; index++) {
        //         const element = apiResults.word_Score_List[index];
        //         ////debugger
        //         let word=element.word
        //         if(element.ending_punctuation!=null)
        //         {
        //             word+=element.ending_punctuation
        //         }
        //         if(index<apiResults.correct_Words-1){
        //             word+=' '
        //         }
        //         this.transcript += word
        //     }
        //     }
        // },
        wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
                end = new Date().getTime();
            }
        },
        showAnswer(){
            try
            {

            
            if(!this.checkUserAccess())
            {
                return this.$alert(
                    "Please upgrade your plan for further practice your today limit is over",
                    "Warning",
                    "warning",
                    {
                        confirmButtonText: "Cannot Proceed"
                    })
            }
            this.marksGot = 0
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserShortAnswer--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.short_question_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            const filePath='audio/UserShortAnswer/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
           
            //console.log(this.microsoftTranscript)
             var param={
                user_audio_file: filePath.replace('audio/',''),
                text: this.microsoftTranscript,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.short_question_id,
                url:'speechace/transcript'
            }
            //console.log(param)
            this.userAnswer.mock_test_id=0
             //console.log(this.microsoftTranscript)
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return alert('No recording found, Please retry.')
                                        
                                    }
                                    //const res=this.answerResponse().data.result

                                    // no need to save json
                                    //console.log('start delay '+ $.now())
                                    this.wait(5000);
                                    //console.log('start delay '+ $.now())
                                    this.userAnswer.transcript=this.microsoftTranscript
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    var answer=this.selectedquestion.transcript //.split(':')
                                    var onlyAnswerWords=(answer.split('Answer').length>0)?answer.split('Answer')[1]:answer.split('Answer')[0];
                                   
                                    onlyAnswerWords=onlyAnswerWords.replace(":","").trim();
                                    let actualAnswerArray=onlyAnswerWords.split("/");

                                    var vm=this
                                    vm.marksGot = 0
                                    
                                     $.each(this.userAnswer.transcript.split(' '),function(index,item){
                                        const word=item.toLowerCase().replace('.','').trim();
                                        $.each(actualAnswerArray,function(index,actitem){
                                            if(actitem.toLowerCase().trim()==word){
                                                vm.marksGot=1
                                            }
                                        });
                                     })
                                    this.apiResults={
                                        transcript:this.microsoftTranscript,
                                        marks:this.marksGot,
                                        text:this.selectedquestion.transcript,
                                        type:'AnswerShort',
                                        src:filePath
                                        }
                                    //this.$refs['result-Modal'].show()
                                    //this.checkAnswerFlag= true 
                                    this.userAnswer.user_result='Your Score: '+this.marksGot+'/1'
                                    this.userAnswer.url='usershortanswer/createshortanswer'
                                    this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
            
             this.actualAnswer = this.selectedquestion.transcript
            }
            catch(error){
                this.show=false
            }
        },
        wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
                end = new Date().getTime();
            }
        },
        showAnswerMarking(){
            try
            {

            
            if(!this.checkUserAccess())
            {
                this.checkAnswerFlag=false;
                return this.$alert(
                    "Please upgrade your plan for further practice your today limit is over",
                    "Warning",
                    "warning",
                    {
                        confirmButtonText: "Cannot Proceed"
                    })
            }
            this.marksGot = 0
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserShortAnswer--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.short_question_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            const filePath='audio/UserShortAnswer/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
             if(this.microsoftTranscript == '') this.wait(5000)
            if(this.microsoftTranscript == ''){
                this.resultStatus='No-Result'
                        this.show=false
                        return this.$alert('No voice found in submitted recording, Please retry.')
            }
             var param={
                user_audio_file: filePath.replace('audio/',''),
                text: this.microsoftTranscript,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.short_question_id,
                url:'speechace/transcriptNewMarking',
                questType:'AnswerShortQuestion',
                keyWords:[],
                questionTranscript:this.selectedquestion.transcript
            }
            //console.log(param)
            this.userAnswer.mock_test_id=0
             //console.log(this.microsoftTranscript)
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data==undefined){
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                                }
                                else if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                                        
                                    }
                                    //const res=this.answerResponse().data.result

                                    // no need to save json
                                    //console.log('start delay '+ $.now())
                                    
                                    //console.log('start delay '+ $.now())
                                    this.userAnswer.transcript=this.microsoftTranscript
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    // var answer=this.selectedquestion.transcript //.split(':')
                                    // var onlyAnswerWords=(answer.split('Answer').length>0)?answer.split('Answer')[1]:answer.split('Answer')[0];
                                   
                                    // onlyAnswerWords=onlyAnswerWords.replace(":","").trim();
                                    // let actualAnswerArray=onlyAnswerWords.split("/");

                                    // var vm=this
                                    // vm.marksGot = 0
                                    
                                    //  $.each(this.userAnswer.transcript.split(' '),function(index,item){
                                    //     const word=item.toLowerCase().replace('.','').trim();
                                    //     $.each(actualAnswerArray,function(index,actitem){
                                    //         if(actitem.toLowerCase().trim()==word){
                                    //             vm.marksGot=1
                                    //         }
                                    //     });
                                    //  })
                                    // this.apiResults={
                                    //     transcript:this.microsoftTranscript,
                                    //     marks:this.marksGot,
                                    //     text:this.selectedquestion.transcript,
                                    //     type:'AnswerShort',
                                    //     src:filePath
                                    //     }
                                    console.log(this.answerResponse());
                                    const markingResult=this.answerResponse().data.apiResults;
                                    this.apiResults=markingResult
                                    this.userAnswer.user_result='Your Score: '+markingResult.marks+'/1'
                                    this.userAnswer.url='usershortanswer/createshortanswer'
                                    this.userAnswer.transcript=this.answerResponse().data.result;
                                    if(this.checkAutoNext) {
                                        this.closeTime=null;
                                        this.closeCounter=5
                                        this.$refs['result-Modal'].show();
                                        this.startTime(); 
                                    }
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
            
             this.actualAnswer = this.selectedquestion.transcript
            }
            catch(error){
                this.show=false
            }
        },
        startTime()
        {
            //var tt;
            if(this.closeCounter==5 && this.closeTime==null){
                this.closeTime=setInterval(()=>{this.startTime()},1000);
                console.log(this.closeCounter)
            }
            else if(this.closeCounter == 0) {
                console.log(this.closeCounter)
                clearInterval(this.closeTime);
                this.$refs['result-Modal'].hide();
                this.$refs.intervalList.changeQuestion('next');
            } else {
                console.log(this.closeCounter)
            this.closeCounter--;
            }
        },
        showAnswerNew(){
            try
            {

            
            if(!this.checkUserAccess())
            {
                return this.$alert(
                    "Please upgrade your plan for further practice your today limit is over",
                    "Warning",
                    "warning",
                    {
                        confirmButtonText: "Cannot Proceed"
                    })
            }
            this.marksGot = 0
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserShortAnswer--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.short_question_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            const filePath='audio/UserShortAnswer/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
           
//Temp
this.microsoftTranscript=(this.microsoftTranscript==null)?"":this.microsoftTranscript;//"It's called painkiller.";

            //console.log(this.microsoftTranscript)
             var param={
                user_audio_file:filePath.replace('audio/',''),//"UserShortAnswer/UserShortAnsweru5_q1813_track1667332582186.wav",//filePath.replace('audio/',''),
                text: this.microsoftTranscript,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.short_question_id,
                url:'deepgram/deepgramTranscript'
            }
            //console.log(param)
            this.userAnswer.mock_test_id=0
             //console.log(this.microsoftTranscript)
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.getTranscripty(param).then(()=>{

                                var deepGramTranscriptJson=this.answerResponse().data.result.results.channels[0].alternatives[0];
                               //console.log(this.answerResponse().data.success)
                                
                                if(this.answerResponse().data.success==1)
                                {
                                    // if(this.answerResponse().data.result.status!='success'){
                                    //     this.resultStatus='No-Result'
                                    //     this.show=false
                                    //     return alert('No recording found, Please retry.')
                                        
                                    // }
                                    //const res=this.answerResponse().data.result

                                    // no need to save json
                                    //console.log('start delay '+ $.now())
                                    //this.wait(5000);
                                    //console.log('start delay '+ $.now())
                                    this.userAnswer.transcript=this.microsoftTranscript=deepGramTranscriptJson.transcript
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    var answer=this.selectedquestion.transcript.split('Answer')[1];
                                    var vm=this
                                    vm.marksGot = 0
                                    //debugger
                                    if(this.userAnswer.transcript!=""){
                                        $.each(this.userAnswer.transcript.split(' '),function(index,item){
                                        if(answer.length>0)
                                        {
                                            //console.log(answer)
                                            //console.log(item.toLowerCase())
                                             if(answer.toLowerCase().includes(item.toLowerCase().replace('.',''))) vm.marksGot=1
                                        }
                                        else{
                                            if(answer.toLowerCase().includes(item.toLowerCase().replace('.',''))) vm.marksGot=1
                                        }
                                    })
                                    }
                                    
                                    this.apiResults={
                                        transcript:this.microsoftTranscript,
                                        marks:this.marksGot,
                                        text:this.selectedquestion.transcript,
                                        type:'AnswerShort',
                                        src:filePath
                                        }
                                    //this.$refs['result-Modal'].show()
                                    //this.checkAnswerFlag= true 
                                    this.userAnswer.user_result='Your Score: '+this.marksGot+'/1'
                                    this.userAnswer.url='usershortanswer/createshortanswer'
                                    this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                else{
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return alert('No recording found, Please retry.')
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
            
             this.actualAnswer = this.selectedquestion.transcript
            }
            catch(error){
                this.show=false
            }
        },
        getResultAndSave(){

        },
        removeRecord (index) {
            this.recordings.splice(index, 1)
        },
        timerEnd(){
            //////console.log(this.$refs)
            //this.$refs.recording.stream(new MediaStream)
           // this.$listeners.onStream()
           
           if(!this.startFlag)
           {
               var aud = document.getElementById("questionRec");
               aud.play()
               this.startFlag = true
               this.title = 'Time'
                aud.onended = function() {
                    $('.ar-icon.ar-icon__lg').click()
                };
           } 
           //aud.play()
            //alert('Timer')
        },
        audioEnd(){
            alert('Ended')
        },
        redoQuest(){
            this.getQuestionDetail(this.selectedquestion.short_question_id)
        },
        playBeep(){
            var sound = document.getElementById("beepAudio");
            sound.playbackRate = 0.5;
            sound.play()
        },
        pauseRecording(){
            $('.ar-recorder__stop').click()
        },
        beforeRecording (msg) {
            this.checkAnswerFlag= false
            this.recordingAllow = true
            this.recordings = []
            //console.log('Before Record: ', msg)
            this.playBeep()
            this.title = 'Time'
            if(this.$refs.timer!=undefined)this.$refs.timer.setTime(0)
            this.userAnswerResponse =''
            this.recordStop= false
            this.fromMic();
            this.recordMessage='Click to Stop'
            $('.ar-recorder__stop').addClass('upFront')
        },
        afterRecording(msg){
            this.startFlag = true
            this.recordMessage='Done'
            this.recognizer.stopContinuousRecognitionAsync();
            //console.log('After Record: ', msg)
            this.recordings=[]
            this.recordings.push({
            src: window.URL.createObjectURL(msg.blob)
            })
            this.$refs.timer.pauseTime()
            //var vm = this;
            this.userAnswer.transcript= this.userAnswerResponse
            this.recordStop= true
            this.recordingBlob=msg.blob
            $('.ar-recorder__stop').removeClass('upFront')
            this.recognizer.stopContinuousRecognitionAsync();
            if(this.checkAutoNext) this.showAnswerMarking();
            // blobToBase64(msg.blob, function (error, base64) {
            //     if (!error) {
            //         //////console.log(base64)
            //         vm.userAnswer.user_recording = base64
            //         ////console.log(vm.userAnswer)
            //         vm.userAnswer.url='usershortanswer/createshortanswer'
            //         vm.saveAnswer(vm.userAnswer).then(()=>{
            //              //console.log(vm.answerResponse())
            //              //vm.userAnswerResponse = vm.answerResponse().data
            //          })
            //     }
            //     else{
            //         ////console.log(error)
            //     }
            // })

        },
        micCheck(msg){
            alert(msg)
        },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        checkRecordingPause(){
            if(this.recordMessage!='Done')
                {
                let myVar = setInterval(function() {
                    
                    if(this.microsoftTranscript!==this.microsoftTranscript & this.recordMessage!='Done')
                    {
                        //console.log('Inside Interval')
                        this.checkRecordingPause()
                    }
                    else{
                        //console.log('Interval stop')
                        clearInterval(myVar);
                        $('.ar-recorder__stop').click()
                    }
                }, 3000);
            }
        },
        fromMic() {
            //console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            //speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "3000"); // 10000ms
            
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('Speak into your microphone.');


            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub: Text=${e.result.text}`);
                }
            };
            this.recognizer.recognized = (s, e) => {
                    if (e.result.reason == 3) {
                     this.microsoftTranscript =e.result.text;
                     //this.checkRecordingPause()
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                        this.pauseRecording()
                        //console.log("NOMATCH: Speech could not be recognized.");
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
        }
    },
    beforeMount(){
        bus.$emit('checkMicPermission');
        this.questionLists=(this.$ls.get('tblShortQuestion')!=null)?JSON.parse(this.$ls.get('tblShortQuestion')):null;
        if(this.questionLists!=null){
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            this.currentUser=userdata
            this.userUsage()
        }
        else{
            this.questionservice('configshortquestion/getallshortquestions').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            this.currentUser=userdata
            this.userUsage()
            });
        }
        
    }
}
</script>
<style scoped>

</style>